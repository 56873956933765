import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import "../styles/detailrando.scss"
import SnapImg from "../components/snapimg"
import SEO from "../components/seo-rando"

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO
        title={post.frontmatter.name}
        description={post.frontmatter.shortdesc}
        keywords={[
          `Trekathlon`,
          `Randonnée`,
          `Rando`,
          `Balade`,
          `Carte`,
          `Travel`,
        ]}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <section class="subpage">
        <div class="title">
          <SnapImg
            filename={post.frontmatter.picture}
            alt={post.frontmatter.name}
          />
        </div>

        <div class="description">
          <div class="country">
            <i class={post.frontmatter.country}></i>{" "}
          </div>
          <div class="randospec">
            <div>
              <i class="em em-world_map"></i> : {post.frontmatter.distance}{" "}
            </div>
            <div>
              <i class="em em-clock230"></i> : {post.frontmatter.time}{" "}
            </div>
            <div>
              <i class="em em-snow_capped_mountain"></i> :{" "}
              {post.frontmatter.cumulativeup}{" "}
            </div>
          </div>
          <div class="published">Published : {post.frontmatter.published}</div>
        </div>
        <div class="content">
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        section
        category
        name
        country
        time
        distance
        cumulativeup
        tag1
        tag2
        tag3
        shortdesc
        picture
        published(formatString: "YYYY/MM/DD")
      }
    }
  }
`
